
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';

import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';

import API from '../../../api';
import { errorHandling } from '../../../utils';
import { useRouter } from 'vue-router';

const defaultValues = {
  title: '',
  email: '',
  address: '',
  name: '',
  phone: '',
  tokens: '',
  password: '',
  password_confirmation: '',
  is_active: true
};

export default {
  components: {
    InputText,
    Button
  },
  setup() {
    const router = useRouter();
    const toast = useToast();
    const { t } = useI18n();

    const state = ref({ ...defaultValues });
    const rules = {
      title: { required },
      email: { required, email },
      address: { required },
      name: { required },
      phone: { required },
      password: { required },
      password_confirmation: { required },
      tokens: {},
      is_active: {}
    };
    const v$ = useVuelidate(rules, state);

    const handleSubmit = async (isFormValid) => {
      v$.value.$touch();
      if (!isFormValid) {
        return;
      }
      try {
        let response = await API.post('admin/companies', state.value);
        state.value = { ...defaultValues };
        toast.add({ severity: 'success', summary: t('success'), detail: t('toast.success_profile'), life: 3000 });
        v$.value.$reset();

        router.push({ name: 'Company', params: { companyID: response.data.id } });
      } catch (error) {
        const { title, message, fields } = errorHandling(error, t);
        toast.add({ severity: 'error', summary: title, detail: message, life: 3000 });
      }
    };
    return {
      v$,
      state,
      handleSubmit
    };
  }
};
